body {
  margin: 0;
  padding: 0;
  font-size: 100%;
}

/* content */
.content {
  font-family: monospace;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.about-2 {
  max-width: 60%;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
